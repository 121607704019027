@keyframes "placeHolderShimmer" {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.card-wrap {
  margin: 30px;
  padding: 30px;
  background: RGBA(0, 0, 0, 0.1);
  //   width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-cell {
  &:last-child {
    margin-bottom: 0px;
  }

  display: flex;
  margin-bottom: 30px;
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #474b99;
  background: linear-gradient(
    to right,
    #3c426c 8%,
    RGBA(0, 0, 0, 0.1) 18%,
    #3c426c 33%
  );
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.image-card {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #474b99;
  background: linear-gradient(
    to right,
    #3c426c 8%,
    RGBA(71, 75, 153, 0.7) 18%,
    #3c426c 33%
  );
  background-size: 800px 104px;
  height: 96px;
  position: relative;
  height: 60px;
  width: 60px;
}

.text-line {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #474b99;
  background: linear-gradient(
    to right,
    #3c426c 8%,
    RGBA(71, 75, 153, 0.7) 18%,
    #3c426c 33%
  );
  background-size: 800px 104px;
  height: 96px;
  position: relative;
  height: 10px;
  width: 230px;
  margin: 4px 0;

  @include bp(max, 500px) {
    width: 120px;
  }
}

.text {
  margin-left: 20px;
}

.WrapCell {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
