// CSS RESET END
//

// text and font stuff
.text--center {
  text-align: center;
}

.textcl--primary {
  color: $primary;
}

.textcl--secondary {
  color: $secondary;
}

.font--small {
  font-size: 12px;
}

.font--medium {
  font-size: 16px;
}

.font--xlarge {
  font-size: 24px;
}

// text and font stuff end

// padding stuff
.padding-tb-20 {
  padding: 20px 0;
}

.padding-tb-10 {
  padding: 10px 0;
}

.padding-tb-40 {
  padding: 40px 0;
}

// padding stuff end

// margin stuff
.margin--auto {
  margin: auto;
}

.margin-b-25 {
  margin: 0 0 25px;
}

// margin stuff end

// flex stuff
.flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex--spacearound {
  display: flex;
  justify-content: space-around;
}

.flex__column--c {
  display: flex;
  align-items: center;
  flex-direction: column;
}

// flex stuff end

//no select text
.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//no select text end

.title {
  @extend .text--center;
  font-family: $bodyFont;
  font-size: $bodySize * 2;

  @include bp (max, $mdWidth) {
    font-size: $bodySize * 1.5;
  }
}

.spaces {
  margin-top: 15px;
  margin-bottom: 15px;
}

.image__wrap {
  position: relative;
}

// TOOLTIP
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 300px;
  min-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;

  @include bp (max, $mdWidth) {
    left: -200px;
  }

  @extend .body-text;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
