.voucher {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid $secondary;
  padding: 2em 1em;
  border-radius: 10px;

  &__span {
    margin-bottom: -17px;
    z-index: 3;
    cursor: pointer;
    padding: .5em;
    text-decoration: underline;
    background: #424E98;
    font-size: 18px;
  }
}

.col__two {
  display: flex;

  &--horizontal {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @include bp (min, 700px) {
      margin-left: 30px;

    }

  }

  ul {
    li {
      .duration {
        @extend .body-text-small;
        display: flex;
        justify-content: flex-end;
        margin: 10px auto;
      }
    }
  }
}

.showTab {
  display: flex !important;
}

.tabHide {
  display: none;
}

// packages back
//
.box__package {
  padding: 2em;
  background: darken($tertiary, 5%);
  border-radius: 4px;
  border: 1px solid gray;
  display: flex;
  min-width: 300px;
  flex-direction: column;

  @include bp (max, 500px) {
    background: transparent;
    border: 0;
  }

  &--info {
    display: flex;
    justify-content: space-between;
  }

  span.info {
    display: flex;
  }

  .info__package {}

  .info__package__three {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
  }

  .info {
    font-size: 16px;
    align-self: flex-end;

    &--detail {
      font-size: 13px;
      color: $white;
      align-self: flex-end;
      text-align: right;
      max-width: 160px;

    }
  }
}

// button package
.tab__justify {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.choose__package {
  appearance: none;
  min-width: 100px;
  cursor: pointer;
  margin: 10px;
  flex: 1;
  padding: .75em;
  border-radius: 4px;
  border: 1px solid gray;
  display: flex;
  justify-content: space-between;
  background: darken($tertiary, 5%);
  @include hoverer(background-color, darken($tertiary, 5%), $secondary);
  text-align: left;
}

.show {
  display: block;
}
