.browser-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.nosupport {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    &>*{
    	margin: 15px;
    }
}
.support-note {
	text-align: center;
	.medium {
		font-size: 24px;
		font-family: $bold;
		color:$yellow;
		margin-bottom: 20px;
	}
}
.nosupports-option {
	&>* {
		margin: 15px;
	}
    &>a {
		cursor: pointer;
        &>img {
            max-height: 200px;
            transition: all ease .5s;
            @include hoverer(filter, grayscale(90%), grayscale(0%)); 
            @include bp(max, 768px){
            	max-height: 120px;
            }
        }
    }
}