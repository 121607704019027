.sectionbox:nth-child(4) {
  .accordion__margin {
    position: relative;
    top: -25px;
  }

  .neobutton {
    bottom: 70px;
  }
}

.sectionbox:nth-child(5) {
  .neobutton {
    width: 160px;
  }

  .titlearco {
    margin-bottom: 10px;
  }

  .pt_logo {
    img {
      width: 140px;
      margin-left: -20px;
      margin-top: -20px;
    }
  }

  .nowgoal_content2 {
    img {
      width: 290px;
    }
  }
}

.titlearco {
  font-size: 22px;
  font-family: $headlineFont;
  color: #49c5fe;
  text-align: center;

  @include bp(max, $mdWidth) {
    font-size: 18px;
    color: $yellow;
  }
}

.accordion {
  // width: 100%;
  color: $white;
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0 auto;

  .yellowAccor {
    color: $yellow !important;
  }

  @extend .no-select;

  & li {
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: flipdown 0.5s ease both;
    border-radius: 4px;

    & h2 {
      margin: 0 30px 0px 0px;
    }

    & i {
      position: absolute;
      transform: translate(-6px, 0);
      margin: 16px 0 0;
      top: 0px;
      right: 15px;

      &:before {
        content: "";
        position: absolute;
        background-color: $white;
        width: 3px;
        height: 9px;
        transition: all 0.25s ease-in-out;
        transform: translate(-3px, 1px) rotate(45deg);
      }

      &:after {
        content: "";
        position: absolute;
        background-color: $white;
        width: 3px;
        height: 9px;
        transition: all 0.25s ease-in-out;
        transform: translate(2px, 0) rotate(-45deg);
      }
    }

    & input[type="checkbox"] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      top: 0px;
      left: 0px;

      &:checked~ {
        & .accordion__menu {
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          transform: translate(0, 50%);
        }

        & i:before {
          transform: translate(2px, 0) rotate(45deg);
        }

        & i:after {
          transform: translate(-3px, -1px) rotate(-45deg);
        }
      }
    }

    &:nth-of-type(1) {
      animation-delay: 0.5s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.75s;
    }

    &:nth-of-type(3) {
      animation-delay: 1s;
    }

    &:nth-of-type(4) {
      animation-delay: 1.25s;
      margin-bottom: 20px;
    }
  }

  &__menu {
    position: relative;
    max-height: 800px;
    font-size: 14px;
    line-height: 30px;
    opacity: 1;
    transform: translate(0, 0);
    margin: 0;
    z-index: 2;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.25s ease-in-out;

    &:hover {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      background: lighten($primary, 10%);
    }
  }
}

.accordionLanding {
  font-size: 20px;
  color: $yellow !important;
  text-align: left !important;
  list-style: none;
  padding: 0;
  margin: 0 auto;

  .yellowAccor {
    color: $yellow !important;
  }

  @extend .no-select;

  & li {
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    animation: flipdown 0.5s ease both;

    & h2 {
      margin: 0 30px 0 0;
    }

    & i {
      position: absolute;
      transform: translate(-6px, 0);
      margin: 16px 0 0;
      top: 0px;
      right: 15px;

      &:before {
        content: "";
        position: absolute;
        background-color: $white;
        width: 3px;
        height: 9px;
        transition: all 0.25s ease-in-out;
        transform: translate(-3px, 1px) rotate(45deg);
      }

      &:after {
        content: "";
        position: absolute;
        background-color: $white;
        width: 3px;
        height: 9px;
        transition: all 0.25s ease-in-out;
        transform: translate(2px, 0) rotate(-45deg);
      }
    }

    & input[type="checkbox"] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      top: 0px;
      left: 0px;

      &:checked~ {
        & a .accordionLanding__menu {
          margin-top: 0;
          max-height: 0;
          display: none;
          transform: translate(0, 50%);
        }

        & i:before {
          transform: translate(2px, 0) rotate(45deg);
        }

        & i:after {
          transform: translate(-3px, -1px) rotate(-45deg);
        }
      }
    }

    &:nth-of-type(1) {
      animation-delay: 0.5s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.75s;
    }

    &:nth-of-type(3) {
      animation-delay: 1s;
    }

    &:nth-of-type(4) {
      animation-delay: 1.25s;
      margin-bottom: 20px;
    }
  }

  &__menu {
    position: relative;
    max-height: 800px;
    font-size: $font-body;
    line-height: 30px;
    color: $white;
    opacity: 1;
    transform: translate(0, 0);
    z-index: 2;
    padding: 15px 0;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.25s ease-in-out;

    &:hover {
      background: darken($primary, 10%);
      color: $secondary;
    }
  }
}

.pt_logo {
  @extend .flex--center;

  img {
    margin-left: -20px;
    height: 100px;

    @include bp(max, $mdWidth) {
      margin-left: -20px;
      height: 70px;
    }
  }
}

.nowgoal_contents {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -45px;
  padding-top: 65px;
  padding-bottom: 65px;

  background: rgba(0, 0, 0, 0.1);
  padding-left: 2em;
  padding-right: 2em;
  border: 1px solid gray;
  border-radius: 3px;

  @include bp(max, $mdWidth) {
    margin-top: -30px;
    padding-top: 15px;
    padding-bottom: 30px;
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}

.nowgoal_content1 {
  & h2 {
    margin: 0 0 20px;
    @extend .font--xlarge;
  }

  & ul li {
    list-style-type: disc;
    @extend .font--medium;

    @include bp(max, $mdWidth) {
      font-size: 14px;
    }
  }
}

.nowgoal_content2 {
  img {
    width: 323px;
  }

  @include bp(max, $mdWidth) {
    img {
      width: 250px;
    }
  }
}

.nowgoal_btn {
  @extend .flex__column--c;
  margin-top: 65px;
}

.prev_step {
  cursor: pointer;
  @extend .font--small;
  @extend .margin-b-25;
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 1;
  }

  80% {
    transform: rotateX(8deg);
  }

  83% {
    transform: rotateX(6deg);
  }

  92% {
    transform: rotateX(-3deg);
  }

  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}

.nowgoal_content1 {
  padding: 1em;
}

.borderCheck {
  border: 1px solid $secondary;
}

.selectedBorder {
  border: 1px solid $secondary !important;
  color: $yellow !important;
  background-color: darken($tertiary, 15%);
}

.reDselect {
  border: 4px solid $red !important;
  color: $yellow !important;
  background-color: darken($tertiary, 15%);
}
