/* ============================ HEADER ========================================== */

.header {
  transition: all 0.6s;
  display: flex;
  max-width: 1200px;
  align-items: center;
  margin: 0 auto;
  padding: 0.5em 3em;
  justify-content: space-between;
  font-family: $primary-font;
  font-size: $font-body;
  min-height: 50px;
  margin-top: 15px;


  @include bp(max, $mdWidth) {
    flex-direction: column;
    padding: 0.5em 1em;
    height: fit-content;
    align-items: none;



  }
}

.half {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex: 10;

  &--rights {
    flex: 1;

    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include bp (max, $mdWidth) {
      justify-content: space-between;

    }

    * {
      font-size: 20px;
      margin: 8px;
    }
  }
}

/* ============================ NAVIGASI & BRAND ========================================== */

.navlogo {


  img {
    max-height: 45px;

    @include bp (max, $mdWidth) {
      max-height: 28px;
    }
  }
}

.toggleNav {
  color: $white;

  @include bp(max, 700px) {
    // display: flex;
  }
}

.open {
  display: flex !important;
}

.navmenu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none; // &__li {


  //     flex: 1;
  //     padding: 1em;
  //     cursor: pointer;
  // }
  @include bp(max, $mdWidth) {
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    padding: 0;


    .hideMeOnPhone {
      display: none;
    }

    .hideMeOnDesktop {
      display: block !important;
    }

  }

  .hideMeOnDesktop {
    display: none;
  }

  &__right--li {
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 35px;

    @include bp(max, 700px) {
      margin-right: 0px;
    }

    @include hoverer(color, $white, $secondary !important);
  }

}
