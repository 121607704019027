.b2Content {
  display: grid;
  grid-template-columns: 4fr 1fr;
  // grid-auto-rows:;
  grid-template-areas: 'im sb'
    'hd sb'
    'ct sb';
  font-family: $primary-font;
  color: $white;
  font-size: $font-body;
  grid-gap: 30px;

  @include bp(max, $mdWidth) {
    grid-template-columns: 1fr;
    grid-template-areas: 'sb sb'
      'hd hd'
      'ct ct'
      'im im';
    grid-gap: 15px;

  }

  &__text>* {
    text-align: left;
    margin-bottom: 15px;

  }

  &__text {
    grid-area: hd;

    @include bp (max, $mdWidth) {
      padding: 0 1.5em;
    }
  }

  &__nav>* {
    margin-bottom: 25px;

    @include bp (max, $mdWidth) {
      margin: 0;
    }
  }

  &__nav {
    grid-area: sb;

    @include bp (max, $mdWidth) {
      margin: 0 1em;
    }

    // justify-self: center;
  }


}

.accordionLanding {
  @include bp(max, $mdWidth) {
    width: 100%;
    display: block !important;
  }
}

.nav__b2c {
  align-self: center;

  &--item {
    margin: 15px auto;
    color: $secondary;
    font-size: $font-body*1.25;
    cursor: pointer;

    &:hover,
    :focus {
      color: $white;
    }
  }

  @include bp (max, $mdWidth) {
    display: none;
  }
}

// image
.imageLanding {
  grid-area: im;

  @include bp (max, $mdWidth) {
    padding: 1em;
  }
}

.landingImage {
  width: 100%;
  max-height: 450px;
  object-fit: cover;
  object-position: top center;
}

///////////////// VIDEO CSS LANDING /////////////////////

.videoLanding {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  &__item>* {
    margin-bottom: 15px;
  }

  .titleVideo {
    justify-self: center;
  }

}
