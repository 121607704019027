.boxforgotpw {
  padding: 30px;

  @include bp (max, $mdWidth) {
    display: flex;
    flex-direction: column;
  }

  .boxform {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-width: 400px;

    @include bp (max, $mdWidth) {
      width: 100%;
    }

    span {
      font-family: $secondary-font;
      margin-bottom: 15px;

    }

    .control {
      margin-top: 15px;

      input {
        width: 100%;
        margin-bottom: 15px;
      }

    }

    button {
      margin-top: 20px;
      margin-bottom: 20px;
    }

  }

}

.boxforgotpw {
  .boxcircle {
    text-align: center;
    margin-bottom: 10px;

    i {
      color: $yellow;
      text-align: center;
    }

  }

  .boxemail {
    margin-top: 20px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include bp (max, $mdWidth) {
      width: 100%;
    }

    span {
      font-family: $secondary-font;
      width: 100%;
      text-align: center;


      a {
        @extend .blue;

      }

    }

  }

}
