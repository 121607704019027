.corelations {
  display: flex;
  flex-direction: column;
  align-items: center;


}

.text-corelations {
  position: absolute;
  margin-top: -25px;
  background: #424E98;
  padding: 1em;
}

.PTprogress {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.equals {
  display: flex;
  align-items: center; // margin: 15px auto;
  color: $white;
  background-color: transparent; // border: 1px solid gray;

  @include bp(max, $mdWidth) {
    padding: 1em;
    background-color: transparent;
  }

  .barPT {
    margin: 0 10px;
  }

  .bWhite {
    background-color: white !important;
  }

  &>.other {
    display: flex;

    flex-direction: column;
    text-align: center;

    &>.level {
      font-size: 15px;
      font-weight: 600;

      @include bp(max, $mdWidth) {
        font-size: 13px;
      }
    }


    &>.line {
      height: 1px;
      min-width: 15px;
      margin: 5px 0;
      background-color: $secondary;
    }

    &>.score {
      font-size: 18px;

      @include bp(max, $mdWidth) {
        font-size: 12px;
      }
    }
  }

  &>.badge {
    img {
      max-height: 80px;

      @include bp(max, $mdWidth) {
        max-height: 50px;
      }
    }
  }
}

// for WEBCAM
.hideCamera {
  display: none;
}

// SSO
section#sso {
  z-index: 10;
}

//////////////////////////////// fr0m html5 //////////////////////////////
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button:focus {
  outline: 0;
}

.warning {
  color: #ff5858;
  display: none;
}

.disNon {
  display: none !important;
}

#progress__bar li.yellow:before,
#progress__bar li.yellow:after {
  background: $yellow;
  color: #333333;
  transition: all 0.5s;
  border-radius: 30px;
}

/*anvas {
            top: 0;
            left: 0;
            position: absolute;
            z-index: -40;
        }*/

.audio__assessment--question {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* PLAY ICON ANIMATION */

//animation keyframes
.stroke-dotted {
  opacity: 0;
  stroke-dasharray: 4, 5;
  stroke-width: 1px;
  transform-origin: 50% 50%;
  animation: spin 4s infinite linear;
  transition: opacity 1s ease, stroke-width 1s ease;
}

.stroke-solid {
  stroke-dashoffset: 0;
  stroke-dasharray: 300;
  stroke-width: 4px;
  transition: stroke-dashoffset 1s ease, opacity 1s ease;
}

.icon {
  transform-origin: 50% 50%;
  transition: transform 200ms ease-out;
}

#speaker {
  display: none;
}

// Hover
#play.active {
  .stroke-dotted {
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-dasharray: 8;
    stroke-dashoffset: 100%;
    opacity: 1;
  }

  .stroke-solid {
    opacity: 0;
    stroke-dashoffset: 10%;
  }

  .icon {
    transform: scale(1.05);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* PLAY ICON ANIMATION END*/

/* ============================ ASSESSMENT ========================================== */

.assessmentClicked {
  background-color: darken($primary, 20%) !important;
  border: 1px solid $secondary !important;
  color: $secondary !important;
}

.assessment__image--ques {
  margin: 2em auto;
  font-family: $primary-font;

  @include bp(max, $mdWidth) {
    padding: 1em auto;
  }
}

// SSO CREATE ACCOUNT
.signup__form {
  padding: 1em;

  // organization code

  .organization {
    background: rgba(#D8D8D8, .5);
    border-radius: 4px;
    padding: .5em 1em;

  }

  @include bp(max, $mdWidth) {
    padding: 0;
  }

  .label {
    font-family: $primary-font !important;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;

  }

  .sso__form__head {
    h3 {
      @extend .blue-text;
    }

    p {
      width: 300px;
      @extend .body-text;
      padding: 10px 0;

      i {
        color: $secondary;
      }
    }
  }

  .field {
    padding: 5px 0;
    margin-bottom: 15px;

  }
}

.field__username {
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  padding: 0 0 5px 0;



  span {
    border-bottom: 1px solid #fafafa;
  }

  .div_upd {
    margin-top: 0px !important;
    border-top: 0px !important;
  }

  .switchText1 {
    width: 88%;
    min-height: 19px;
    background: transparent;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fafafa;
    @extend .body-text;
  }

  .grayed {
    color: #606983;
  }

  .btn_save {
    cursor: pointer;
  }

  .btn_cancel {
    cursor: pointer;
    margin-left: 5px;
  }

  .iconEdit {
    float: right;
    margin-right: 10px;
  }
}

// SSO CREATE ACCOUNT END
// ============================= Assessment Placements Test ================ //
.pt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pt__title {
  font-family: $headlineFont;
  text-align: center;

  h2 {
    font-size: 20px;
  }

  h1 {
    font-size: 30px;
  }
}

// .pt__image {}

// ============================= Assessment Form Sign Up ================ //
.sectionbox:nth-child(3) {
  .neobutton {
    bottom: 100px;
  }
}

.assignup {
  display: flex;
  flex-direction: column;
}

.signup__title {
  align-self: center;

  &>img {
    margin-top: 3em;
    margin-bottom: 2em;
    max-height: 250px;
    height: 75px;

    @include bp(max, $mdWidth) {
      max-height: 200px;
      height: 50px;


    }
  }
}



// input class
// control
.control {
  display: flex;

  &__upload {
    @include bp(max, $mdWidth) {
      flex-direction: column;
    }

    &>.fa {
      cursor: pointer;
      margin: -20px 15px 0 0;
      align-self: center;

      @include bp(max, $mdWidth) {
        margin: 15px;
      }

      span {
        font-family: $primary-font;
        @extend .body-text;
        margin-left: 5px;
      }
    }
  }
}

.image__profile {
  position: absolute;
  right: 145px;
  top: 24%;

  @include bp (max, $mdWidth) {
    position: absolute;
    right: 65px;
    top: 24%;
  }


  &--text {
    position: absolute;
    top: 150px;
    right: 235px;

    @include bp(max, $mdWidth) {
      align-self: center;
      top: 130px;
      right: 135px;
      font-size: 21px !important;
    }

    @include bp(max, $mdWidth) {
      align-self: center;
      top: 135px;
      right: 96px;
      font-size: 14px !important;
    }
  }
}

.image__profile2 {
  position: absolute;
  right: 125px;
  top: 24%;

  @include bp (max, $mdWidth) {
    position: absolute;
    right: 65px;
    top: 24%;
  }


  &--text {
    position: absolute;
    top: 150px;
    right: 200px;



    @include bp(max, $mdWidth) {
      align-self: center;
      top: 135px;
      right: 110px;
      font-size: 14px !important;
    }
  }
}

.image__upload {
  margin: 0px 20px 20px 20px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid gray;
  object-fit: cover;
  object-position: center;

  @include bp(max, $mdWidth) {
    align-self: center;
    margin: 10px;
    // margin-top: -15px;
    width: 30px !important;
    height: 30px !important;
  }
}

.upload {
  padding: 5px;
  background-color: $primary;
  border: 1px solid #636c83;
  border-radius: 4px;
  @extend .spaces;
  display: flex;
  align-items: center;
  flex-direction: column;

  &>.optional {
    margin-top: -35px;
    background-color: $tertiary;
    padding: 1em;

    @include bp(max, $mdWidth) {
      margin-top: 5px;
      padding: 0;
    }
  }
}

.profile__upload {
  padding: 1em;
  display: flex;

  @include bp(max, $mdWidth) {
    flex-direction: column;

    a {
      margin: 15px;
      align-self: flex-start !important;
    }
  }
}

.input {
  flex: 1;
  padding: 10px 15px;
  min-height: 48px;
  margin: 0;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: #E6EDEF;
  font-size: 16px;
  color: black;
  min-width: 270px;
  border-radius: 3px;

  @include bp (max, $mdWidth) {
    min-width: 180px;
    width: 100%;

  }
}

.signin {
  text-align: center;
  position: relative;
  color: #fff;
}

// new from irawan
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button:focus {
  outline: 0;
}

.warning {
  color: #ff5858;
  display: none;
}

.timer__assessment {
  display: none;
  position: fixed;
  color: $yellow;
  @extend .body-text;
  padding: 0.5em;
  background: darken($tertiary, 20%);
  border-radius: 4px;
  right: 10%;
  top: 12%;
  text-align: center;
  min-width: 50px;
  min-height: 25px;

  @include bp(max, $mdWidth) {
    right: 15%;
  }

  @include bp(max, $mdWidth) {
    top: 15%;
    right: 5%;
  }
}

.showTime {
  display: block;
}

.hideTime {
  display: none;
}

// circle number
.circle__number {
  @extend .white-bold;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  /* the magic */
  text-align: center;
  color: $white;
  font-size: 16px;
  margin-right: 15px;
  background-color: $secondary;
  text-transform: uppercase;
}

.number {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.payment {
  &__option {
    display: flex;
    justify-content: space-between;
  }

  &__items {
    @include hoverer(background-color, darken($tertiary, 5%), darken($tertiary, 10%));
    border: 1px solid gray;
    padding: 1em;
    border-radius: 4px;
    text-align: center;
    flex: 1;
    margin-right: 5px;
    cursor: pointer;
  }

  &__result {
    background-color: darken($tertiary, 5%);
    border: 1px solid gray;
    padding: 1em;
    border-radius: 4px;
    text-align: center;
    flex: 1;
    margin: 5px;

    &--divider {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--button {
      display: flex;
      justify-content: center;

      @include bp(max, $mdWidth) {
        flex-direction: column;
        align-items: center;
      }

      button {
        margin: 15px;
      }
    }

    // &--items {}

    hr {
      flex: 1;
      border: 0.5px solid darken(gray, 5%);
    }
  }
}

.note {
  text-align: left;
  margin: 15px 0px;
}

.fieldInput {
  flex: 1;
  margin: 5px;
}

.method {
  &__choose {
    display: flex;
    flex-direction: column;

    &>* {
      margin: 15 0 0 0;
    }

    &>.profile {
      &>.certificate {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
      }
    }
  }

  &__text {
    display: flex;
  }

  &__option {
    display: flex;
    max-width: 400px;
  }
}

// zip input change
.zip {
  flex: 0;
}

// content on error css
.errorBox>* {
  margin-bottom: 15px;
}

.errorBox {
  z-index: 5;
  display: flex;
  flex-direction: column;
  font-family: $primary-font;
  color: $white;
  padding: 15px;
  max-width: 300px;

  &__title {
    @extend .title;
    font-family: $bold;
    color: $yellow;
    text-align: left;
  }

  &__image {
    img {
      height: 150px;
    }
  }

  &__message {
    font-size: 16px;

    & p>a {
      color: $secondary;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
  }

  &__button>* {
    margin-bottom: 10px;
  }
}

.list {
  &__title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
  }

  &__item {
    text-align: start;
    margin-bottom: 15px;
    font-size: 18px;

    @include bp (max, $mdWidth) {
      // font-size: 15px !important;
      text-align: center;
    }
  }
}
