html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.tooltips {
  width: 20px;
  border-radius: 25px;
  background: black;
  /* text-align: center; */
  height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.box {

  &__accordion,
  &__assessment,
  &_payment,
  &__cert {
    max-width: 100%;
    padding: 1em 2em;
    display: flex;
    background-color: rgba($primary, 0.1);
    // border-top: 7px solid $lineColorBox;
    // border-bottom: 7px solid $lineColorBox;
    flex-direction: column;
    justify-content: center;
    font-family: $bodyFont;
    color: #fff;
    font-size: $bodySize;
    border-radius: 4px;

    @include bp(max, $mdWidth) {
      background-color: transparent;
      border: 0;
      padding: 0.5em 1em;
      min-width: 275px;
    }

    @include bp(max, $iphoneS) {
      padding: 0.5em .75em;
    }
  }

  &__review {
    max-width: 800px;
    padding: 1em 2em;
    display: flex;
    background-color: $white;
    flex-direction: column;
    justify-content: center;
    font-family: $bodyFont;
    color: #333;
    font-size: $bodySize;
  }

  &__profile {
    padding: 1em 2em;
    font-family: $bodyFont;
    font-size: $bodySize;
    color: $white;
    background-color: rgba($primary, 0.8);
    border-top: 7px solid $lineColorBox;
    border-bottom: 7px solid $lineColorBox;

    &>.wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      // &>* {
      //   // margin: 15px 0 0 0;
      // }
    }
  }

  &__accordion {
    &--notif {
      padding: 1em;
      display: none;
      font-size: 14px;
    }
  }

  &__assessment {
    display: flex;
    font-size: 15px !important;

    @include bp(max, $mdWidth) {
      font-size: 14px;
    }
  }

  &__contactUs {
    @extend .box__assessment;
    align-items: center;
  }

  // CSS for Darken box
  &__cert {
    background: rgba(#252E48, .2);
    text-align: center;
    width: 100%;
    border-radius: 10px;
    margin: 20px 15px;

    // padding: 1em;
    @include bp (max, $mdWidth) {
      margin: 0 auto;
    }

  }

  &__container {
    display: flex;
    justify-content: center;

  }

  &__subscription {
    display: flex;
    text-align: start;
    padding: 1.5em 1em;
  }

  &__between {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    &--text {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__total {
    display: flex;
  }


}


/* new css from robert soerbakti */

.box__assessment {
  .boxcontactus {
    width: 80%;

    @include bp(max, $mdWidth) {
      width: 90%;
    }

    h3 {
      font-family: $secondary-font;
      font-size: 1em;
      letter-spacing: 0.3px;
      margin-bottom: 10px;

      @include bp(max, 768px) {
        text-align: center;
      }
    }

    .control {
      display: flex;
      flex-direction: column;

      label,
      a {
        font-family: $secondary-font;
        font-size: 0.9em;
        margin-top: 10px;
      }
    }

    .controlbtn {
      margin-top: 30px;
      margin-bottom: 20px;

      @include bp(max, $mdWidth) {
        width: 100%;
      }

      a {
        font-family: $secondary-font;
        font-size: 0.9em;
      }

      button {
        padding: 0.4em;
        letter-spacing: 0.5px;
        margin-top: 0;
        margin-right: 0;

        @include bp(max, $mdWidth) {
          min-width: 140px;
        }
      }
    }
  }
}

/* ============================ Global Settings ========================================== */

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
}

.wrapper {
  z-index: -100;
  min-height: 100%;
}

// FORM INPUT
.phone {
  &__text {
    position: relative;
    color: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  &--selection {
    padding: 10px;
    color: black;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    max-width: 30px;
    font-size: 13px;
    border: 0;
    margin-right: 2px;
    border-radius: 3px;
    background: #fff;
  }
}

//------------Settings Box  Selection In-------------------//
.liveportalsec {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 3em;
  max-width: 1000px;
  margin: 0 auto;

  @include bp(max, $mdWidth) {
    padding: 0;
  }
}

.liveportalError {
  height: 100%;
  @extend .liveportalsec;

  @include bp(max, $mdWidth) {
    padding: 0;
  }
}

.sectionbox {
  display: flex;
  flex-direction: column;


  @include bp (max, $mdWidth) {
    max-width: 350px;
  }

  @include bp (max, $iphonePlus) {
    max-width: 335px;
  }

  @include bp (max, $iphone) {
    max-width: 300px;
  }

  @include bp (max, $iphoneS) {
    max-width: 285px;
  }

}

.sectionbox:not(:first-of-type) {
  display: none;
}

.flex__horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapAll {
  min-height: 100%;
}

.footer {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 1em;
  font-family: $primary-font;
  color: $white;
  background-color: transparent;

  &__first {
    flex: 1;
    text-align: center;
  }

  &__last>* {
    padding: 5px 15px;
    font-size: 16px !important;
    cursor: pointer;
    @include hoverer(color, $white, $secondary !important);
  }

  &__wrapper {
    align-items: center;
  }

  @include bp(max, $mdWidth) {
    padding: 1em;
  }
}

//-------------------------- BOXES LAYOUT -------------------------- //
// reset color
.-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}

//------------Assessment QUESTION-------------------//

.question {
  margin-bottom: 15px;
  margin-top: 15px;

  &__text {
    max-width: 500px;
    margin: auto;

    @include bp(max, $mdWidth) {
      max-width: 320px;
    }
  }

  &__image {
    display: flex;
    justify-content: center;

    img {
      max-height: 150px;
    }
  }

  &__audio {
    display: flex;
    justify-content: center;

    &--option {
      font-size: 24px !important;
      padding: 0em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      border-radius: 50px;
    }
  }

  &__imageAndText {
    display: flex;

    &--img {
      flex: 1;
      display: flex;

      img {
        max-height: 150px;
        margin: auto;
      }
    }

    &--txt {
      flex: 4;
    }
  }

  &__audioAndText {
    display: flex;

    &--audio {
      flex: 1;
      margin-right: 15px;
    }

    &--txt {
      flex: 4;
    }
  }
}

.answer {

  // text styles
  &__text {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;

    @include bp(max, $mdWidth) {
      max-width: 320px;
    }

    &--box {
      margin-top: 10px;
      background: rgba(black, 0.1);
      border: 1px solid gray;
      padding: 0.5em 1em;
      font-family: $bodyFont;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
        background-color: darken($primary, 20%);
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &--option {
      padding: 1em;
      background: rgba(black, 0.1);
      border: 1px solid gray;
      margin: 10px;

      &:hover {
        cursor: pointer;
        background-color: darken($primary, 20%);
      }
    }

    img {
      max-height: 130px;

      @include bp(max, $mdWidth) {
        max-height: 90px;
      }
    }
  }

  &__audio {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &--option {
      font-size: 40px !important;
      padding: 1em;
      background: rgba(black, 0.1);
      border: 1px solid gray;
      margin: 10px;

      &:hover {
        cursor: pointer;
        background-color: darken($primary, 20%);
      }
    }
  }
}

.webcam {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &>* {
      font-family: $primary-font !important;
      color: $white !important;
    }

    &>.text {
      margin: 15px auto;

      & ul>li {
        margin: 15px auto;

        @include bp(max, $mdWidth) {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1in;
    font-family: $primary-font !important;

    @include bp(max, $mdWidth) {
      padding: 15px;
    }

    &>span {
      margin: 15px auto;
      font-size: 13px;
      font-style: italic;
      color: $yellow;
    }
  }

  &__items {
    &--border {
      border: 1px solid;
      position: absolute;
      min-width: 170px;
      min-height: 170px;
      border-radius: 10px;
      margin-top: -65px;

      @include bp(max, $mdWidth) {
        display: none;
      }
    }
  }
}

.receipt {
  min-width: 760px;
  width: 100%;


  @include bp (max, $mdWidth) {
    min-width: 500px;

  }

  @include bp (max, $mdWidth) {
    min-width: 450px;
    margin-left: 0;
    margin-right: 0;

  }

  @include bp (max, $mdWidth) {
    min-width: 350px;

  }

  @include bp (max, $mdWidth) {
    min-width: 325px;


  }

  &__header {
    background: $secondary;
    padding: 1em;
    border-radius: 4px 4px 0 0;

    &--h1 {
      color: $white;
      font-size: 30px;
      font-weight: 600;

      span {
        color: inherit;
        font-size: 15px;
      }
    }
  }

  &__content {
    padding: 1em;
    background: $white;
  }

  &__group {
    &--head {
      font-size: 12px;
      color: #ABABAB;
    }

    &--name {
      font-size: 16px;
    }

    &:first-child {
      margin-right: 15px;
    }

    width: 100%;
  }

  &__total {
    background: rgba($secondary, .2);
    padding: 1em 1.25em;
  }

  &__box {
    padding: 1em;
    border-radius: 10px;
    border: 1px solid #D8D8D8;
  }
}

.checkout {
  min-width: 300px;
  max-width: 650px;

  @include bp (max, $mdWidth) {
    max-width: 300px;
  }

  &__header {
    >p {
      @include bp (max, $iphoneS) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;

    * {
      margin-bottom: 15px;
    }


    &--two {
      color: $white;

      display: flex;
      flex-direction: row;
      justify-content: space-between;


    }
  }
  // hide class



  // &__header {}

  &__footer {
    text-align: center;
  }
}
.hideOnPhone {
  display: inline;

  @include bp (max, $mdWidth) {
    display: none;
  }
}