label#language-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: rgba(darken($tertiary, 20%), 0.5);
  padding: 10px 15px; //   border: 1px solid $lineColorForm;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.24);
  border-radius: 3px;

  &:hover,
  :active,
  :visited {
    color: white;
  }

  >* {
    margin-right: 5px;
  }

  i.flag {
    width: 25px;
  }

  &:after {
    content: "";
    position: relative;
    display: block; // top: 30px;
    // right: 10%; // right: 5px;
    right: -5px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 6px solid $yellow;
    border-bottom: 0 solid $yellow;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: border-bottom 0.1s, border-top 0.1s 0.1s;

    @include bp(max, $mdWidth) {
      right: 0px;
    }
  }
}

input:checked~label#language-label:after {
  border-top: 0 solid $yellow;
  border-bottom: 6px solid $yellow;
  transition: border-top 0.1s, border-bottom 0.1s 0.1s;
}

input:checked~ul.submenu {
  max-height: 800px;
  transition: max-height 0.5s ease-in; //   width: inherit;
  z-index: 300;

}

input.language {
  display: none !important;
}

ul.submenu {
  max-height: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
  background: transparent;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  transition: max-height 0.5s ease-out;
  position: absolute;
  background: white;
  margin-top: 5px; //   top: 20px;
  border-radius: 3px; //   min-width: 100%;
}

ul.submenu li a {
  display: flex;
  font-size: 14px;
  color: #333;
  text-decoration: none; //   border-radius: 3px;
  padding: 10px 15px; //   box-shadow: 0 -1px rgba(0, 0, 0, 0.5) inset;
  transition: background 0.3s; //   white-space: nowrap;

  &:hover {
    background: #f3f3f3; // color: yellow;
  }
}
