.token-wrapper {
	display: flex;
	justify-content: space-between;
	
	> *{
		margin:15px; 
	}
	@include bp (max, 768px) {
		flex-direction:column;
	}
	> .tokens-button {
		display: flex;
		flex-direction: column;
		align-items:center;
		&> * {
			margin-bottom: 20px;
		}
	}
}
// select option style
.convert{ 
	padding: 5px;
	background: darken($tertiary, 15%);
	border: 0;
	outline: 0;
	border-radius: 3px;
	color: $yellow;
	height: 35px;
	font-family: $bold;
	font-size: 14px;
}// select option style
.method {

	& > .item-method {
		padding: 1em;
		background: darken($tertiary, 10%);
		margin-bottom: 15px;
		border-radius: 3px;
		font-size: 13px;
		cursor: pointer;
	}
}
.tokens {

	position: relative;
	>.circle {
		@extend .title-yellow;
		display: flex;
		width: 70px;
		height: 70px;
		border: 1px solid $yellow;
		align-items: center;
		text-align: center;
		justify-content: center;
		border-radius: 50%;
		margin: 15px;
		background: transparent;
		appearance: textfield;	
	}
	>.plus {
		background: darken($tertiary, 10%);
		padding: .5em;
		align-self: center;
		color: $yellow;
		font-size: 20px;
		cursor: pointer;
	}
	>.minus {
		@extend .plus;
	}
	>.totals {
		@extend .circle;
		background: transparent;
		font-size: 16px;
		padding: 1em;
		border-radius: 3px;
		width: auto;
		height: auto;	
		appearance: textfield;	
	}
	>.changeTotals {
		@extend .totals;
	}
	>.dollar {
		position: absolute;
		left: 35px;
		font-family: $bold;
		color:$yellow;
		top: 16px;
	}
	>.head {
		position: absolute;
		top: -20px;
		left: 50px;
		padding: .5em;
		background: rgba($primary, 1);
	}

	>.notes {
		max-width: 450px;
		margin: 0 auto;
		font-size: 13px;
	}
}
.currency-option {
	display: flex;
	flex-direction: column;
	& *>{
		margin-bottom: 15px;
	}

}