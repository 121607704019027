// ========================== PAYMENT ================== //





.box_payment {
	justify-content: flex-start;
	margin-bottom:30px; 
}

.payment__title {
	font-size: 25px;
	font-family: $headlineFont;
	color: #49c5fe;
	margin-top: 10px;
	text-align: center;
}

.payment__ul {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;


	ul {
		display: flex;
		justify-content: space-around;
		color: #fff;
		font-family: $headlineFont;
		font-size: $bodySize;
		width: 100%;
		border: 1px solid #636c83;
		cursor: pointer;

		li {
			background-color: rgba(70, 87, 138, 0.3);
			width: 100%;
			padding: 5px;
			text-align: center;
		}

		li.current {
			background-color: rgba(70, 87, 138, 0.9);
			transition: all 0.7s;
		}

	}

}

.payment {
	display: flex;
}

.payment__credit {
	display: flex;
	flex: 1;
}

.accountinfo {
	width: 50%;
	text-align: left;
	margin-right: 15px;

	label {
		color: #fff;
		position: relative;
		font-size: 13px;
	}

	

}

.paymentsDetails {
	flex:1;
	text-align: left;

	label {
		color: #fff;
		position: relative;
		font-size: 12px;
	}

	.input1 {
		margin-top: 19px;
	}

	.btn__payment {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.neobutton {
		width: 150px;
	}

}

.cardnum {
	display: flex;

	input {
		width: 55px;
	}

	span {
		margin: 0 5px 0 4px;
		position: relative;
		font-weight: bold;
		color: #fff;
		top: 5px;
	}

	.span2 {
		margin-right: 75px;
	}

}

.labelnum {
	display: flex;
	justify-content: space-between;
	width: 90%;
}

.tab-content {
	display: none;
}

.tab-content.current {
	display: flex;
}