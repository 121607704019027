.ptresult {
  align-self: center !important;
  font-size: 14px !important;
  @include hoverer(color, $secondary !important, $white !important);
}

.btn {
  @extend .text-center;
  appearance: none; // text-transform: uppercase;
  padding: 1em 1em;
  border-radius: 50px;
  margin: 20px 20px;
  cursor: pointer;
  font-family: $bodyFontThin;

  &__primary {
    @include hoverer(background-color, $secondary, darken($secondary, 10%));
    transition: all $ease-in-quad 0.2s;
  }

  &__secondary {
    background-color: transparent;
  }
}

.-red {
  @include hoverer(background-color, #EE3D49 !important, darken(#ee3d49, 15%) !important);
  color: $white !important;
  border: transparent !important;
}

.btn-small {
  @extend .body-text-small;
  @include hoverer(background, transparent, $secondary);
  // @include hoverer(color, $secondary, $white !important);
  transition: all ease 0.5s;
  // color: $secondary !important;
  border: 1px solid $secondary;
  padding: 0.5em 1em !important;
  border-radius: 50px;
  cursor: pointer;
}

.btn-small-no-border {
  // @extend .btn-small;
  @extend .body-text-small;
  border: none !important;
  color: $secondary !important;
  transition: all ease .3s;
  @include hoverer(color, $secondary, $yellow !important);
  padding: 0.5em 1em !important;
}

.btn-medium {
  @extend .btn-small;
  font-size: 16px;
}

// social media button
.flexbtn {
  display: flex !important;
  font-family: $primary-font; // justify-content: center;
  color: $white !important;
  border: 0px !important;

  &>* {
    margin-left: 15px;
  }

  min-width: 120px !important;
}

.facebook {
  background-color: #4a66ac !important;
  @include hoverer(background-color, #4a66ac, darken(#4a66ac, 10%) !important);
}

.twitter {
  background-color: #4d9fec !important;
  @include hoverer(background-color, #4d9fec, darken(#4d9fec, 10%) !important);
}

.linkedin {
  background-color: #2d3d49 !important;
  @include hoverer(background-color, #2d3d49, darken(#2d3d49, 10%) !important);
}

//------------Button Neo Settings-------------------//

// linear gradient

$buttonlinear: linear-gradient(to bottom, rgba(#fff, .2), rgba(#000, .1));

.neobutton {

  background: lighten(#424E98, 10%);
  // border: 1px solid rgba($white, .2);
  border-radius: 100px;
  margin: 0 auto;
  padding: 0.5em 1.25em;
  transition: all ease .3s;
  cursor: pointer;
  font-weight: 500;
  margin-top: 15px;
  font-size: 18px;
  min-height: 40px;
  min-width: 220px;

  &:hover {
    background: $secondary;
    border: 1px solid $secondary;
    color: $white;


  }

  &__outline {
    background: $white;
    color: $secondary;
    border: 1px solid $secondary;

    &:hover {
      background: $secondary !important;
      color: $white !important;
      border: 1px solid $white !important;
    }
  }

  &__small {
    margin: 0;
    min-width: 0;
    min-height: 0;


  }



  &__green {

    border: 0;
    background: $green;

    &--small {
      margin: 0;
      min-width: 0;
      min-height: 0;
    }

    &:hover {
      background: darken($green, 5%);
      border: 0;

    }

  }


  // @include hoverer(background-color, $tertiary, $secondary);

  @include bp(max, $mdWidth) {
    padding: 0.5em 1em;
  }

  &__white {
    @extend .neobutton;
    background: none;
    border: 1px solid rgba($white, .2);
    color: $white;
    font-family: $primary-font !important;
    min-width: 250px;

    @include bp(max, $iphoneS) {
      min-width: 220px;

    }

    &>i {
      font-size: 16px;

      &>span {
        @extend .white;
      }
    }
  }

  &__red {
    @extend .neobutton;
    @include hoverer(background-color, #ee3d49, darken(#ee3d49, 15%));
    padding: 0.5em 1em;
    color: $white;
    border: 0;
    min-width: 120px;
  }

  &__blue {
    @extend .neobutton__red;
    @include hoverer(background-color, $secondary, darken($secondary, 15%));
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 100px 55px;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.neo {
  &__select {
    padding: 1em;
    background: $primary;
    color: $white;
    font-weight: 500;
    padding: 1em .5em;
    font-size: 16px;
  }
}
