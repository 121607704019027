// All variables to include font CERA

@font-face {
  font-family: neoFontRegular;
  src: url('../font/Cera GR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: neoFontBold;
  src: url('../font/Cera GR Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: neoFontThin;
  src: url('../font/Cera GR Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



// BASE TYPOGRAPHY START


$bodyFont: 'neoFontRegular',
sans-serif;
$bodyFontThin: 'neoFontThin',
sans-serif;
$headlineFont: 'neoFontBold',
sans-serif;
$bodySize: 16px;

$primary: #3F4E7B;
// primary color
$secondary: #49C5FE;
// secondary color
$lineColorForm: #5367A4;
// line color at box login
$white: #fff;

.text-center {
  text-align: center;
}

.title {
  @extend .text-center;
  font-family: $bodyFont;
  font-size: $bodySize * 2;

  @include bp (max, $mdWidth) {
    font-size: $bodySize * 1.5;
  }
}
