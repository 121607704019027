.user-certificate {
  display: flex;
  flex-direction: column;

  >* {
    margin: 15px;
  }
}

.modal-certificate {
  display: none;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(darken($primary, 30%), 0.7);
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.profile-certificate {
  max-width: 500px;
  margin: 0 auto;
  position: relative;

  &>.certificate {
    max-width: 500px;

    @include bp(max, $mdWidth) {
      max-width: 300px;
    }
  }

  &>.profile {
    position: absolute;
    top: 150px;
    right: 40px;
    width: 40px;
    height: 40px;
    border: 1px solid gray;
    border-radius: 50%;

    @include bp(max, $mdWidth) {
      top: 75px;
      right: 20px;
      width: 40px;
      height: 40px;
    }
  }

  &>.name {
    @extend .blue-text;
    position: absolute;
    top: 183px;
    left: 100px;
    font-size: 32px;
    font-family: $bold;

    @include bp(max, $mdWidth) {
      font-size: 20px;
      top: 110px;
      left: 70px;
      margin: auto;
    }

    // @include bp (max, 450px) {
    //     font-size: 14px;
    //     top: 130px;
    //     left: 60px;
    //     margin: auto;
    // }
  }
}

.certif-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &>* {
    margin: 10px;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;

  &>* {
    margin-bottom: 20px;
  }

  &>.circle-image {
    &>.image {
      max-width: 60px;
    }
  }

  &>.button {
    border: 1px solid $secondary;
    padding: 0.5em 0.75em;
    border-radius: 50px;
    font-size: 14px;
    transition: all ease 0.5s;
    cursor: pointer;
    @include hoverer(color, $secondary, $white);
    @include hoverer(background, transparent, $secondary);
  }

  &>.disabled {
    color: gray !important;
    cursor: not-allowed !important;
    border: 1px solid gray !important;
    pointer-events: none !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}
