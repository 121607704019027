/* reset */

// content start modification
.headInvoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;

  @include bp (max, $mdWidth) {
    margin-bottom: 15px;
  }

  .title {
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;

    @include bp (max, $mdWidth) {
      font-size: 20px;
      font-family: $bold;
    }
  }

  img {
    max-height: 45px;

    @include bp (max, $mdWidth) {
      max-height: 50px;
    }
  }
}

.user {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @include bp (max, $mdWidth) {
    align-items: flex-start;
    flex-direction: column;

    &>* {
      margin: 15px;
    }
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include bp (max, $mdWidth) {
    width: 100%;
    flex: none;
  }

  &__name {
    font-size: 24px;
    font-family: $bold;
    margin: 15px 0px 5px 0px;

    @include bp (max, $mdWidth) {
      font-size: 20px;
    }
  }

  u {
    text-decoration: none;
    border-bottom: 1px solid black;
    margin: 0px 15px 15px 0px;
  }

  &__items {
    font-size: 13px;

    @include bp (max, $mdWidth) {
      font-size: 10px;
    }
  }
}

.userButton {
  align-self: center;

  .neobutton {
    -webkit-appearance: none;
    background-color: transparent;
    color: #49C5FE;
    cursor: pointer;
    padding: .5em 1em;
    border: 1px solid #49C5FE;
    border-radius: 50px;

    &:hover {
      background-color: #49C5FE;
      color: white;
    }
  }

  .neoRed {
    @extend .neobutton;
    font-size: 16px;
    background-color: #EE3D49;
    color: white !important;
    border-color: #EE3D49 !important;

    &:hover {
      background-color: #EE3D49;
      color: white !important;
    }
  }
}






/* table */

table {
  border-collapse: collapse;
}

.inventory {
  display: table;
  width: 100%;
  margin-top: 25px;


  // thead {}

  // tbody {}

  tr {




    th {
      font-weight: 500 !important;
    }

    th,
    td {
      border: 0;
      padding: 1em;
      text-align: center;
      background: $white;


      @include bp (max, $mdWidth) {
        padding: 5px;
      }
    }

    td {
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      vertical-align: middle;
    }

    .numbering {
      text-align: center;
    }

    .description {
      &__items {
        line-height: 1.5 !important;
        font-size: 11px !important;
        text-align: left !important;

        @include bp (max, $mdWidth) {}

        &--title {
          font-size: 14px !important;
          font-weight: 600;
        }
      }
    }
  }
}

.balance {
  margin-top: 2em;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  tr {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  th,
  td {
    padding: 1em;
    background: white;
  }

  th {
    font-weight: 500;
  }

  td {
    font-size: 20px;
    font-weight: 500;
  }
}


th {
  border-color: #BBB;

  @include bp (max, $mdWidth) {
    font-size: 11px;
  }
}

td {
  border-color: #DDD;
}





/* page */

// place in on body for print result a4
.invoice {
  padding: 0.5in;
  box-sizing: border-box;
  font-family: $primary-font;
  height: auto;
  margin: 1in auto;
  overflow: hidden;
  padding: 0.5in;
  min-width: 300px;
  max-width: 8.5in;
  background: #FFF;
  border-radius: 1px;
  color: #273454;

  @include bp (max, $mdWidth) {
    padding: 15px;
    margin: 0 auto;
  }

}





/* header */

.headerInvoice {
  margin-bottom: 30px;
  width: 100% !important;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.terms {
  margin: 15px auto;
  font-size: 11px;

  a {
    color: blue;
  }
}

@media print {
  .invoice {
    width: 8.5in;
  }
}
