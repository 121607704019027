.flex {
  display: flex;
}

.flexy-content {
  display: flex;
  justify-content: space-between;

  @include bp (max, 620px) {
    flex-direction: column;

    // >* {
    //   margin-bottom: 25px;
    // }
  }
}

.f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &__button {
    flex-wrap: wrap;

  }
}

.jfe {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.jfs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.jsa {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.jsb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.jc {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ac {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.as {
  align-items: start;
}

.fc {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.nw {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.w {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.al-f {
  align-self: flex-start;
}


.al-c {
  align-self: center;
}

.al-e {
  align-self: flex-end;
}

.fr {
  flex-direction: row;
}

/**
 * Utility styles
 */

.inliner {
  display: inline-block;
  vertical-align: middle;

  +.inlined {
    display: inline-block;
    vertical-align: middle;
  }
}


/**
 * Component styles
 */

.pmr {
  margin: 40px auto 40px;
  padding: 80px 60px;
  counter-reset: point;

  .tr {
    position: relative;
    height: 4px;
    background: #ddd;
  }

  .progress {
    -moz-transition: width 1s;
    -o-transition: width 1s;
    -webkit-transition: width 1s;
    transition: width 1s;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 4px;
    background: #2b89b9;
  }

  .pr-p {
    position: relative;
    margin: -14px 0 0;
    padding: 0;
    list-style: none;

    .progress-point {
      &:nth-child(1) {
        left: 0%;
      }

      &:nth-child(2) {
        left: 16.66%;
      }

      &:nth-child(3) {
        left: 33.33%;
      }

      &:nth-child(4) {
        left: 50%;
      }

      &:nth-child(5) {
        left: 66.66%;
      }

      &:nth-child(6) {
        left: 83.33%;
      }

      &:nth-child(7) {
        left: 100%;
      }
    }
  }

  .progress-point {
    -moz-transition: color 1s;
    -o-transition: color 1s;
    -webkit-transition: color 1s;
    transition: color 1s;
    position: absolute;
    display: block;
    width: 100px;
    height: 60px;
    margin-left: -50px;
    text-align: center;
    cursor: pointer;
    color: #ddd;

    &.completed,
    &.active {
      color: dimgray;
    }

    &.completed:before {
      border-color: #777;
      background-color: #777;
    }

    &.active:before {
      -moz-transition-delay: 1s;
      -o-transition-delay: 1s;
      -webkit-transition-delay: 1s;
      transition-delay: 1s;
      border-color: #777;
    }

    .label:before {
      counter-increment: point;
      content: counter(point) ". ";
      /* Display the counter */
    }
  }
}

@media screen and (max-width: 460px) {
  .pmr {
    margin: 5px auto -15px;
    padding: 90px 20px;
  }
}


/*
 * Demo controls
 */

.controls {
  text-align: center;
  font-size: 12px;
}

.trigger {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 8px;
  border: none;
  font: 14px inherit;
  text-shadow: 0px 1px 1px rgba(251, 251, 251, 0.75);
  color: #626262;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JiYmJiYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzk1OTU5NSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #bbbbbb), color-stop(100%, blue));
  background-image: -moz-linear-gradient(#bbbbbb, #959595);
  background-image: -webkit-linear-gradient(#bbbbbb, #959595);
  background-image: linear-gradient(#bbbbbb, #959595);
}

#label0,
#label1,
#label2,
#label3,
#label4,
#label5,
#label6 {
  margin-top: -10px;
  margin-bottom: 0px;
  height: 45px;
  width: 100%;
}

.before {
  opacity: 1;
}

.active {
  opacity: 1;
}

#userhere,
#goalhere {
  height: 80px;
  margin-top: -95px;
}

@media screen and (max-width: 760px) {
  .pmr .progress-point {
    width: 40px;
    height: 0;
    margin-left: -19px;
  }

  #label0 {
    content: url('../../media/img/start.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #label1 {
    content: url('../../media/img/A1.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #label2 {
    content: url('../../media/img/A2.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #label3 {
    content: url('../../media/img/B1.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #label4 {
    content: url('../../media/img/B2.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #label5 {
    content: url('../../media/img/C1.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #label6 {
    content: url('../../media/img/C2.svg');
    margin-top: 0;
    height: 30px;
    width: 100%;
  }

  #goalhere {
    content: url('../../media/img/GOAL.svg');
    height: 50px;
    position: inherit;
    margin-top: -65px;
    margin-left: 0px;
  }

  #userhere {
    content: url('../../media/img/HERE.svg');
    height: 50px;
    position: inherit;
    margin-top: -65px;
    margin-left: 0px;
  }
}

.tooltip-inner {
  max-width: 100px !important;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.imgpp {
  position: relative;
}

.tooltip {
  display: inline-block !important;
}

#label23 {
  content: url(popover-dyned.svg);
  margin-top: -5px;
  display: block;
  text-align: center;
  transform-origin: 50% 50%;
  animation: grow 2s cubic-bezier(0, 1.44, 1, -0.34);
}

@keyframes grow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes grow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// CIRCLE INSIDE
.circo {
  transition: all $ease-in-quad .2s;

  &.act {
    top: -17px;
    left: 20px;
    /* bottom: 300px; */
    position: absolute;
    width: 60px !important;
    height: 60px;
    border-radius: 100px;
    border: 4px solid $secondary;
    z-index: 30;
    opacity: 1;

    @include bp (small) {
      width: 35px !important;
      height: 35px;
      top: -6px;
      left: -1px
    }

    @include bp (max, 760px) {
      width: 40px !important;
      height: 40px;
      top: -9px;
      left: -3px
    }
  }
}

//circle end
