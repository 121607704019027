.notification {
	margin-bottom: 15px;
	
	min-width: 70%;
	&>.success {
		display: flex;
		justify-content: space-between;
		padding: 1em;
		border-radius: 4px;
		background: $green;
		flex:1;
		color: $white;
		font-family: $bold;
		&>.close {
		line-height: unset;
	}
	}

	&>.failed {
		@extend .success;
		background: $red;
	}
}