$primary: #424E98; // primary color
$secondary: #49c5fe; // secondary color
$tertiary: #3f4e7b; // solid blue dark color
$yellow: #FECA49; //solid yellow text color
$lineColorForm: #636c83; // line color at box login form
$lineColorBox: #5367a4;
$red: #d83636;
$green: #26B2A1;
$colors: ("primary": ("light": tint($primary, 20%),
    "normal": $primary,
    "dark": shade($primary, 20%)),
  "secondary": ("light": tint($secondary, 20%),
    "normal": $secondary,
    "dark": shade($secondary, 10%)),
  "gray": ("ultralight": #eeeeee,
    "light": #cccccc,
    "normal": #9b9b9b,
    "dark": #666666,
    "ultradark": #444444),
  "other": ("white": #ffffff,
    "black": #4a4a4a,
    "success": #26b2a1,
    "warn": #f1a94e,
    "alert": #ef737b));

// Primary
$primary-light: map-get(map-get($colors, "primary"), "light");
$primary-dark: map-get(map-get($colors, "primary"), "dark");
$primary-dense: map-get(map-get($colors, "primary"), "dense");

// Secondary
$secondary-light: map-get(map-get($colors, "secondary"), "light");
$secondary-dark: map-get(map-get($colors, "secondary"), "dark");

// Grays
$gray-ultralight: map-get(map-get($colors, "gray"), "ultralight");
$gray-light: map-get(map-get($colors, "gray"), "light");
$gray: map-get(map-get($colors, "gray"), "normal");
$gray-dark: map-get(map-get($colors, "gray"), "dark");
$gray-ultradark: map-get(map-get($colors, "gray"), "ultradark");

// Misc
$white: map-get(map-get($colors, "other"), "white");
$black: map-get(map-get($colors, "other"), "black");
$success: map-get(map-get($colors, "other"), "success");
$warn: map-get(map-get($colors, "other"), "warn");
$alert: map-get(map-get($colors, "other"), "alert");

.bg-primary {
  background: $primary;
}

.bg-secondary {
  background: $secondary;
}

.bg-lightBlue {
  background: $tertiary;
}

.bg-yellow {
  background: $yellow;
}

.bg-red {
  background: $red;
}

.bg-green {
  background: $green;
}

// Layout/Grid
// ------------------------
$min-width: 20rem;
$max-width: 87.5rem;
$mdWidth : 624px;
$iphonePlus : 414px;
$iphone: 375px;
$iphoneS: 320px;

$num-columns: 12;
$grid-width: $max-width;
$grid-gutter: 1rem;
$grid-container-prefix: "row";
$grid-item-prefix: "col";

// Misc
// ------------------------
$chevron-left: "&#x3008;";
$chevron-right: "&#x3009;";
$close: "&#x2715;";
$quote-left: "\201c";
$quote-right: "\201d";
