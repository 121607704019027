.video-grid,
.video-page {
  max-width: 900px; // margin: 0 auto;
  padding: 0;
}

ul.video-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

li.video {
  // flex-grow: 1;
  position: relative;
  overflow: hidden;
  list-style-type: none;
  width: calc(100% / 2 - 0.4em);
  border: solid 0.2em transparent;

  @include bp (max, $mdWidth) {

    width: 100% !important;

  }

  & a {
    outline: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .15s;
  }

  & figure {
    display: block;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    & img {
      display: block;
      max-width: 100%;
      height: auto;
      opacity: 0;
      transform: scale(0.5);
      transition: all .20s;
    }

    & figcaption {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 1em;
      padding: 1rem;
      transform: translateY(0);
      opacity: 1;
      transition: all .20s;
    }
  }

  &:hover {
    & figure {
      & img {
        opacity: 1;
        transform: scale(1);
      }

      & figcaption {
        opacity: 0;
        transform: translateY(50%);
      }
    }
  }

  &:hover:before {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
