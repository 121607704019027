.imagePhone {
  max-height: 400;
  margin: 0 35px;
}

.successPay {
  display: flex;
  padding: 0 1in;
  // align-items: center;
  max-width: 800px;
  z-index: 10;
  font-family: $primary-font;
  color: $white;
  align-items: center;

  @include bp (max, 768px) {
    flex-direction: column;
    padding: .5in;
    justify-content: center;
  }

  &__phone {
    flex: 1;
    margin-right: 15px;

    @include bp (max, 768px) {
      margin: 0 auto;
    }
  }

  &__content {
    flex: 3;
    flex-direction: column;

    &--buttonPay {
      max-height: 40px;
      // margin-right: 15px;
      margin-left: -15px;
    }

    &--title {
      @extend .title-white;
      display: flex;
      justify-content: flex-start;


      align-items: center;

      i {
        color: $yellow;
        margin-right: 15px;
      }
    }

    &--button {
      display: flex;
      // min-width: 700px;

      justify-content: space-between;

      @include bp (max, 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &--item {
        // min-width: 30%;
        align-items: center;
        margin-right: 15px;


        @include bp (max, 768px) {
          width: auto;
        }
      }

    }

    &--share {
      display: flex;
      align-items: center;

      @include bp (max, 768px) {

        flex-direction: column;
        align-items: center;
      }


    }
  }

  .share__text {
    margin: 15px 0px;
  }

  .share__icon {
    font-size: $font-body *2;

    &--social {
      margin: 15px;
    }
  }

  .buttonPay {

    margin-bottom: 25px;


    .big {
      font-size: 18px;
      font-weight: 800;
      display: inline;
    }

  }
}
