.profile__nav {
    background: rgba(darken($primary, 30%), 0.3);
    padding: 10px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    >* {
        margin-right: 10px;
    }

    &--image {

        width: 25px;
        height: 25px;
        border-radius: 50%;
        // margin: 20px;

        object-fit: cover;
        object-position: center right;

    }

    &--text {
        font-size: 14px;
    }
}