.neosystem {
	@extend .liveportalsec;
}
.system-content {
	&>* {
		margin-bottom: 15px;
	}
	&>.image {
		width: 100%;
		max-height: 300px;
		object-fit: cover;
    	object-position: center;
    	padding: 1em;
	}
}
.neo-divide {
	display: flex;
	flex-wrap: wrap;
	padding: 1em;
	@include bp (max, 500px) {
		flex-direction: column;
	}
	&>*{
		margin-bottom: 15px;
	}
	&>.image {
		width: 100%;
		height: 300px;
		// max-height: 300px;
		background-image: url('../../assets/img/forneosystem/macLive.png');
		background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-image: none;
	}
	&>.left {
		flex:1;
		text-align: center;
		.image{
			max-width: 320px;
			min-width: 300px;
		}
	}
	&>.right {
		flex:1;
		min-width: 280px;

	}
}
