/** VARIABLES
===================================*/


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  img {
    max-width: 100%;
    display: block;
  }
}

.bxslider {
  margin: 0;
  padding: 0;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
}


/** THEME
===================================*/

.bx-wrapper {

  box-shadow: 0 0px 10px darken(gray, 50%);
  background: #fff;

  .bx-pager,
  .bx-controls-auto {
    position: absolute;
    bottom: -30px;
    width: 100%;
  }

  .bx-loading {
    min-height: 50px;
    ;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
  }

  .bx-pager {
    text-align: center;
    font-size: .85em;
    font-family: Arial;
    font-weight: bold;
    padding-top: 20px;

    &.bx-default-pager a {
      background: rgba(darken(gray, 60%), 0.7);
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      outline: 0;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;

      &:hover,
      &.active,
      &:focus {
        background: rgba($yellow, 0.7);
      }
    }
  }

  .bx-pager-item,
  .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    vertical-align: bottom;
    *zoom: 1;
    *display: inline;
  }

  .bx-pager-item {
    font-size: 0;
    line-height: 0;
  }

  .bx-prev {
    left: -80px;

    @include bp (max, $mdWidth) {
      left: 10px;
    }

    // &:hover,
    // &:focus {
    //   // background-position: 0 0;
    // }
  }

  .bx-next {
    right: -80px;

    @include bp (max, $mdWidth) {
      right: 10px;
    }

    &:hover,
    &:focus {
      background-position: -43px 0;
    }
  }

  .bx-controls-direction a {
    position: absolute;
    top: 50%;
    outline: 0;
    min-width: 50px;
    margin-top: -15px;
    color: $white;
    font-size: 24px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;
    background-color: rgba(darken($tertiary, 15%), 0.8);

    // media query
    @include bp (max, $mdWidth) {
      font-size: 20px;
      min-width: 7mm;
      min-height: 7mm;
    }

    &.disabled {
      display: none;
    }
  }

  .bx-controls-auto {
    text-align: center;

    .bx-start {
      display: block;
      width: 10px;
      height: 11px;
      outline: 0;
      margin: 0 3px;

      &:hover,
      &.active,
      &:focus {
        background-position: -86px 0;
      }
    }

    .bx-stop {
      display: block;
      width: 9px;
      height: 11px;
      outline: 0;
      margin: 0 3px;

      &:hover,
      &.active,
      &:focus {
        background-position: -86px -33px;
      }
    }
  }

  .bx-controls.bx-has-controls-auto.bx-has-pager {
    .bx-pager {
      text-align: left;
      width: 80%;
    }

    .bx-controls-auto {
      right: 0;
      width: 35px;
    }
  }

  .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: .25em;
    font-family: $font-body !important;
    color: $yellow;
    font-style: 16px;
    text-transform: uppercase;
    background: rgba(darken($tertiary, 5%), 0.8);
    width: 100%;

    span {
      color: darken(gray, 50%);
      font-family: $font-body;
      display: block;
      font-size: .85em;
      padding: 10px;
    }
  }
}


/* LOADER */


/* PAGER */


/* DIRECTION CONTROLS (NEXT / PREV) */


/* AUTO CONTROLS (START / STOP) */


/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */


/* IMAGE CAPTIONS */
