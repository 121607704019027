.timeline {
  display: flex;

  transform: rotate(180deg);

  > li {
    transform: rotate(-180deg);
  }

  list-style: none;
  width: 100%;
  max-width: 500px;
  justify-content: center;
  padding: 0 32px;

  &__img.active {
    border: 2px solid white;
    border-radius: 50px;
    // margin: 25px;
  }

  &__img.position {
    transform: scale(1.3);
    animation: beat 0.8s infinite alternate;
    transform-origin: center;
  }

  @keyframes beat {
    to {
      transform: scale(1.2);
    }
  }

  &__img {
    height: 70px;

    &.special {
      transform: scale(1.5);

      @include bp(max, 769px) {
        transform: scale(1.2);
      }
    }

    &.start {
      transform: scale(1.5);
    }
  }

  &-image.target:after {
    content: "Target";
    color: white;
    position: absolute;
    left: 19px;
    top: -15px;
    transform-origin: 30% 50%;
  }

  > li {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      margin-top: -64px;
      //select all after first child from above
    }

    &:nth-child(odd) {
      align-self: flex-end;
      //   background: orange;
      border-radius: 50px;
    }

    &:nth-child(even) {
      align-self: flex-start;
      //   background: blue;
      border-radius: 50px;
    }

    .line {
      z-index: -3 !important;

      &:before {
        width: 2px;
        height: 90px;
        background-color: #364572;
      }

      &.active:before {
        background-color: #4a90e2;
      }
    }

    &:nth-child(odd) .line:before {
      content: "";
      position: absolute;
      top: 36px;
      bottom: 0;
      left: 10px;
      -webkit-transform: rotate(40deg);
    }

    &:nth-child(even) .line:before {
      content: "";
      top: -20px;
      right: 74px;
      position: absolute;
      bottom: 0;
      -webkit-transform: rotate(-40deg);

      /* Safari */
    }
  }

  // RESPONSIVE SETTINGS

  @include bp(max, 769px) {
    flex-direction: column;

    max-width: 235px;
    min-width: 235px;
    transform: rotate(0deg);

    > li {
      transform: rotate(-0deg);
    }

    // TARGET TEXT
    &-image.target:after {
      content: "Target";

      left: -30px;
      top: 40%;
    }

    // LINE

    .line {
      //   z-index: -3 !important;

      &:before {
        height: 50px;
      }

      &.active:before {
      }
    }

    li {
      &:nth-child(odd) .line:before {
        position: absolute;
        top: 36px;
        bottom: 0;
        left: 10px;
        -webkit-transform: rotate(66deg);
      }

      &:nth-child(even) .line:before {
        top: 36px;
        right: 10px;
        position: absolute;
        bottom: 0;

        /* IE 9 */
        -webkit-transform: rotate(-66deg);
      }
    }
  }
}

.corelationContainer {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  white-space: nowrap;
  align-items: flex-start;

  @include bp(max, 769px) {
    margin-top: 15px;
    flex-direction: column;
    white-space: normal;
    margin-top: 50px;
  }

  // select child
  > * {
    background-color: rgba(60, 75, 123, 0.66);
    border-radius: 10px;
    padding: 1em 2em;
    margin: 15px;

    @include bp(max, 769px) {
      padding: 0.5em;
    }
  }
}

//typography
.PTbox {
  margin: 15px 0;
  border-radius: 0;
  padding: 1em 2em;
  text-align: center;
}

.title-level {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
}

.barPTImage {
  width: 13px;
}
