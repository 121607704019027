// terms and condition scss by rpbert soerbakti
// edited by Gaffy AGB Hassan
.terms-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    &>.terms-content {
        max-width: 960px;
        min-height: 600px;
        margin: 30px 0 30px 0; // background-color: rgba(0, 0, 0, 0.33);
        border-radius: 3px; // border: 1px solid #49C5FE;

        @include bp (max, 768px) {
            margin: 0;
            width: 100%;
        }

        &>.head {
            width: 100%;
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &>.links {
                font-size: 0.9em;
                font-family: $secondary-font;
                font-weight: bold;
                @extend .blue;
                display: flex;
                align-items: center;
                min-width: 130px;
                justify-content: space-between;
            }

            &>.h1 {
                margin-top: 10px;
                font-size: 3em;
                @extend .body-text-large;
                @extend .yellow-bold;
                font-family: $secondary-font;

                @include bp (max, 768px) {
                    font-size: 1.5em;
                }
            }
        }

        &>.content-wrapper {
            width: 100%;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &>.content {
                // max-width: 80%;
                // min-height: 90%;
                margin-bottom: 5em;
                border-radius: 3px; // border: 1px solid #49C5FE;
                background-color: rgba(darken($tertiary, 10%), .8);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @include bp (max, 768px) {
                    // width: 90%;
                }

                &>.boxcard {
                    background-color: rgba(darken($tertiary, 30%), 0.13);
                    font-family: $secondary-font;
                    margin: 0 3em 1em 3em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-radius: 3px;
                    padding: 5px;

                    @include bp (max, 768px) {
                        margin: 0 1em 1em 1em;
                    }

                    &:nth-child(1) {
                        margin-top: 70px;
                    }

                    &:nth-child(6) {
                        margin-bottom: 70px;
                    }

                    &>.title {
                        font-size: 1.2em;
                        margin: 10px 0px 10px 20px;
                        @extend .blue;
                        text-align: left;
                    }

                    &>.messages {
                        font-size: 1em;
                        margin: 0px 10px 10px 20px;
                        @extend .white;

                        &>.links {
                            color: $secondary;
                            font-size: .9em;
                        }
                    }
                }
            }
        }
    }
}

// terms and condition only
.ol-terms {
    counter-reset: item;

    >.li-terms {
        display: block;
    }

    >.li-terms:before {
        content: counters(item, ".") " ";
        counter-increment: item;
    }
}

.-blue {

    @extend .blue-bold;
    font-size: 20px;

    >.small {
        font-size: 13px;
        margin: 20px 0 0 20px;
    }

    >.small-white {
        @extend .small;
        color: $white;
    }
}

.-ext {
    margin: 0 0 0 25px !important;
}

.terms-text {
    font-family: $primary-font;
    margin: -18px 0 0 25px;

    &-ext {
        font-family: $primary-font;
        margin: -18px 0 0 35px;
    }
}