.small-none {
  @include bp(max, 450px) {
    display: none !important;
  }
}

.smaller-font {
  @include bp(max, 450px) {
    font-size: 15px;
  }
}

.smaller-body-font {
  @include bp(max, 450px) {
    font-size: 14px;
  }
}

.mt10 {
  margin-top: 2em;
  position: relative;
}

.divider {

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 6px;
    top: -15px;
    width: 100px;
    border-radius: 100px; // background-color: $;
    background-color: darken($primary, 15%);
  }
}

.mbsmall {
  margin-bottom: 15px !important;
}

.mr-2 {
  margin-right: 2em !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.no-border {
  border: 0 !important;
}
