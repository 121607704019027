.flex-down {
	display: flex;
	flex-direction: column;
}
.flex-between {
	display: flex;
	justify-content: space-between;
}
.flex-around {
	display: flex;
	justify-content: space-around;
}
.flex-center {
	display: flex;
	justify-content: center;
}
.align-center {
	align-items: center;
}
.flex-start {
	display: flex;
}