/* Form Progress */

.progress {
  width: 100%;
  margin: 40px auto;
  text-align: center;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &.myLevel {
    margin: 15px auto;
  }





  >.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: darken($tertiary, 10%);
    width: 55px;
    height: 55px;
    border-radius: 40px;

    &.myLevel {
      @include bp (max, $mdWidth) {
        width: 60px;
        height: 60px;
      }
    }

    @include bp (max, $mdWidth) {
      width: 40px;
      height: 40px;
    }

    ;

    >span {

      &.now,
      &.goals {
        position: absolute;
        margin-top: -50px;

        @include bp (max, $mdWidth) {
          margin-top: -35px;

        }
      }
    }



    @include bp(min, 624px) {
      width: 82px;
      height: 82px;
    }
  }

  >.active {
    border: 2px solid $secondary;
  }

  .preA1 {
    background: #B2ABF2 !important;



  }

  // Label Class
  .label {
    color: white !important;
    font-size: 20px !important;
    font-weight: bold;

    @include bp (max, $mdWidth) {
      font-size: 15px !important;
    }
  }

  .A1 {
    background: #70B2E2 !important;



  }

  .A2 {
    background: #3374BA !important;


  }

  .B1 {
    background: #81C784 !important;


  }

  .B2 {
    background: #42A047 !important;


  }

  .C1 {
    background: #AC6CAD !important;



  }

  .bar {
    background: #fff;
    border-radius: 40px;
    width: 35px;
    height: 4px;
    margin-left: -5px;
    margin-right: -5px;

    margin-bottom: 5px;
    z-index: -3;
    border-radius: 0;

    @include bp(max, $mdWidth) {
      width: 15px;

    }

    @include bp (max, $iphoneS) {
      width: 10px;
    }

  }

  .circle {
    .label {


      margin-top: 0;
      color: $white;
      font-size: 12px;

    }
  }

  .bar.active,
  .circle.active {
    .label {
      color: darken($tertiary, 10%);
    }

    background: $yellow;
  }
}

/* Done / Active */

//------------Progres bar-------------------//
.progresss {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress__title {
  color: #aab0c2;
  font-family: $primary-font;
  font-size: $bodySize;
  display: flex;
  min-width: 700px;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;

  @include bp(max, 600px) {
    min-width: 400px;
  }

  @include bp(max, 400px) {
    min-width: 370px;
    font-size: 13px;
  }
}

.spanactive {
  display: block;
  color: $white;
  transition: all 2s;
  font-size: 36px;
  font-weight: 500;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    width: 60px;
    margin-left: -30px;
    border-bottom: 6px solid $secondary;
  }

  @include bp(max, 500px) {
    font-size: 16px;
  }
}

/*progress__bar*/

#progress__bar {
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  display: inline-flex;
  min-width: 350px;

  @include bp(max, 600px) {
    min-width: 150px;
  }

  @include bp(max, 320px) {
    min-width: 320px;
  }

  li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    width: 33.33%;
    float: left;
    position: relative;
    border: none;
  }

  li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 29px;
    display: block;
    font-size: 16px;
    font-family: $primary-font;
    font-weight: bold;
    color: #fff;
    background: transparent;
    margin: 0 auto 5px auto;
    text-align: center;
    position: relative;
    z-index: 5;
  }

  /*progress__bar connectors*/
  li:after {
    content: "";
    position: absolute;
    top: 13px;
    z-index: 1;
    /*put it behind the numbers*/
    width: 100%;
    background: transparent;
    position: absolute;
    left: -50%;
  }

  li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
  }

  /*marking active/completed steps green*/
  /*The number of the step and the connector before it = green*/
  li.active:before,
  li.active:after {
    background: transparent;
    color: $secondary;
    transition: all 2s;
  }
}

// result PT
#result {
  overflow: hidden;
  /*CSS counters to number the steps*/
  display: flex;
  width: 100%;
  min-width: 600px;

  @include bp(max, 500px) {
    min-width: 350px;
  }

  li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    float: left;
    position: relative;
    border: none;
    display: flex;
    justify-content: center;

    p {
      position: absolute;
      align-self: center;
      z-index: 5;
      color: #ffffff; // color: #46578A;

      @include bp(max, 500px) {
        font-size: 13px;
      }
    }
  }

  li:before {
    content: "";
    width: 70px;
    height: 70px;
    line-height: 29px;
    display: block;
    font-weight: bold;
    color: #636c83;
    background: #28324f;
    border-radius: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 5;

    @include bp(max, 500px) {
      width: 55px;
      height: 55px;
    }
  }

  /*progress__bar connectors*/
  li:after {
    content: "";
    position: absolute;
    top: 25px;
    z-index: 1;
    /*put it behind the numbers*/
    width: 100%;
    height: 10px;
    background: #28324f;
    position: absolute;
    left: -50%;

    @include bp(max, 500px) {
      top: 17px;
    }
  }

  li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
  }

  /*marking active/completed steps green*/
  /*The number of the step and the connector before it = green*/
  li.active:before,
  li.active:after {
    background: #f7f052;
    color: white;
    text-shadow: 0px 1px 10px #fff;
    transition: all 2s;
  }

  li.active {
    p {
      // color: #ffffff;
      color: #46578a;
    }
  }
}

// package
#package__bar {
  overflow: hidden;
  /*CSS counters to number the steps*/
  display: inline-flex;
  width: 100%;
  min-width: 600px;

  @include bp(max, 500px) {
    min-width: 0;
  }

  li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    float: left;
    position: relative;
    border: none;
    display: flex;
    justify-content: center;

    p {
      position: absolute;
      align-self: center;
      z-index: 5;
      color: #ffffff; // color: #46578A;
    }
  }

  li:before {
    content: "";
    width: 60px;
    height: 60px;
    line-height: 29px;
    display: block;
    font-size: 13px;
    font-weight: bold;
    color: #636c83;
    background: #28324f;
    border-radius: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 5;
  }

  /*progress__bar connectors*/
  li:after {
    content: "";
    position: absolute;
    top: 25px;
    z-index: 1;
    /*put it behind the numbers*/
    width: 100%;
    height: 10px;
    background: #28324f;
    position: absolute;
    left: -50%;
  }

  li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
  }

  /*marking active/completed steps green*/
  /*The number of the step and the connector before it = green*/
  li.active:before,
  li.active:after {
    background: #f7f052;
    color: white;
    text-shadow: 0px 1px 10px #fff;
    transition: all 2s;
  }

  li.active {
    p {
      // color: #ffffff;
      color: #46578a;
    }
  }
}

// LINE AT PACKAGE BAR
.linecont {
  width: 100%;

  .linehr {
    background: white;
    height: 2px;
    width: 35px;
    margin: auto;
    margin-top: 10px;
  }

  .linehrfull {
    background: white;
    height: 1px;
    width: 100%;
    margin: auto;
  }

  .linehrDark {
    background: darken($tertiary, 20%);
    height: 1px;
    margin: auto;
    margin-top: 15px;
  }
}

.cross {
  position: relative;
  display: inline-block;
}

.cross::before,
.cross::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}

.cross::before {
  border-bottom: 2px solid red;
  -webkit-transform: skewY(-15deg);
  transform: skewY(-15deg);
}

//
//
.progressDefault {
  display: none;
}
