@import url("https://use.typekit.net/urp1fdh.css");

h1 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

span,
p {
  font-size: 16px;
}


// Variables start
// ------------------------
$font-body: 16px !default;
// regular font
$primary-font: "canada-type-gibson",
sans-serif;
$secondary-font: "canada-type-gibson",
$primary-font;
$font: $secondary-font;
$title-font: "canada-type-gibson",
sans-serif;

// bold font
$bold: "canada-type-gibson",
sans-serif;

// Enable debugging features
$debug: false;

.white {
  color: $white;
  font-family: $primary-font;
}

.white-bold {
  color: $white;
  font-family: $bold;
  font-weight: 500 !important;
}

.bold {
  color: $white;
  font-family: $bold;
}

.blue {
  color: $secondary;
  font-family: $title-font;
}

.blue-bold {
  color: $secondary;
  font-family: $bold;
}

.yellow-bold {
  color: $yellow;
  font-family: $bold !important;
}

.yellow-thin {
  color: $yellow;
  font-family: $secondary-font;
}

.yellow-regular {
  color: $yellow;
  font-family: $primary-font;
}

// START FOR PAGE USE
//
.title-white {
  @extend .white;
  font-size: $font-body * 2;

  @include bp(max, 500px) {
    font-size: $font-body * 1.5;
  }
}

.title-yellow {
  @extend .yellow-bold;
  font-size: $font-body * 2;
}

.yellow {
  @extend .yellow-bold;
  font-size: $font-body;
}

.yellow-med {
  @extend .yellow-bold;
  font-size: $font-body * 1.3;
}

.body-yellow-bold {
  @extend .yellow-bold;
  font-size: $font-body * 2;
}

.title-white-bold {
  @extend .white;
  font-size: 64px;
  font-weight: 500;

  @include bp(max, 500px) {
    font-size: $font-body * 1.5;
  }
}

.title {
  // @extend .blue;
  font-size: $font-body * 2;
  font-weight: bold;

  @include bp(max, 500px) {
    font-size: $font-body * 1.5;
  }
}

.body-text {
  @extend .white;
  font-size: $font-body;
}

.body-text-small {
  @extend .white;
  font-size: $font-body * 0.75;
}

.body-text-smallBold {
  @extend .white-bold;
  font-size: $font-body * 0.75;
}

.body-text-bold-medium {
  @extend .white-bold;
  font-size: $font-body * 1.25;
}

.body-text-large {
  @extend .white;
  font-size: $font-body * 1.25;
}

.money-text {
  @extend .yellow-bold;
  font-size: $font-body * 4;
}

.money-text-regular {
  @extend .yellow-bold;
  font-size: $font-body * 1.5;
}

.money-text-thin {
  @extend .yellow-thin;
  font-size: $font-body;
}

.blue-text {
  @extend .blue;
  font-size: $font-body * 1.5;
}

.blue-text-dark {
  color: darken($secondary, 20%);
  font-size: $font-body * 1.5;
}

.blue-text-small {
  @extend .blue;
  font-size: $font-body;
}

// link text color hover function
// a {
//     @include hoverer(color, $white, $secondary);
// }
