// menu modal
.inputMenu {
  transition: all 0.7s ease 2s;
}

.inputMenu:focus {
  // width: 200px;
  outline: 0;
}

.searchText {
  background: darken($tertiary, 30%);
  border: 0;
  font-size: 100%;
  overflow: hidden;
  resize: none;
  display: block;
  height: 40px;
  padding: 15px;

  @include bp(max, 500px) {
    font-size: 50%;
  }

  color: $yellow;

  &:focus {
    outline: 0;
  }
}

.formSearch {
  display: flex;
  flex-direction: column;
  font-family: $primary-font;

  &>* {
    margin: 10px;
  }
}

.modalSearch {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: ". . ."". content ."". . .";
  grid-gap: 15px;
  color: rgba($white, 0.3);
  font-size: 175%;
  height: 100%;

  &__search {
    grid-area: content;
    justify-self: center;
  }

  .closeSearch {
    cursor: pointer;
    padding: 5px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.menuModal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: $primary-font;
  font-size: 24px;
  text-align: center;
  flex-direction: column;

  @include bp(max, 500px) {
    font-size: 16px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    min-width: 200px;
  }

  &>* {
    color: $white;
    padding: 15px;
    cursor: pointer;
  }

  &__items {
    padding: 15px;
    @include hoverer(color, $white, $secondary);

    @include bp(max, 500px) {
      padding: 3px;
    }
  }
}

.showMenu {
  display: block !important;
}

.modalPreventScroll {
  // method call in for body to stop scrolling when modal shown
  overflow-y: hidden;
}

.modalSocial {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  background: rgba(black, 0.3);
  display: none;
  z-index: 6;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

// prolog page
.prolog-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  font-family: $primary-font;
  color: $white;
  padding: 1.5em;
  height: 100vh;



  @include bp(max, 768px) {
    height: auto;
  }

  &>.content-down {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>.title-prolog {
      font-weight: 500;
      font-size: 36px;

      text-transform: capitalize;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        width: 60px;
        margin-left: -30px;
        border-bottom: 6px solid $secondary;
      }
    }

    @include bp(min, 900px) {
      display: flex;
    }

    // target all childreen css
    &>* {
      margin-bottom: 40px;
    }

    &>.image {

      // flex: 1;
      // align-self: flex-start;
      img {
        max-height: 150px;

        @include bp(max, 400px) {
          max-height: 80px;
        }
      }
    }
  }

  &>.close {
    font-size: 24px;
    font-family: $bold;
    color: $white;
    margin: 30px 0;
  }

  &>.buttonWrap {
    // font-size: 40px;
    display: flex;
    justify-content: space-between;

    &>.btn {
      font-size: 18px;
      color: $white;
      border-radius: 50%;
      width: 15px;
      border: 2px solid $white;
      // @include hoverer(background-color, rgba($yellow, .5), rgba($yellow, 1));
    }

    &>.dotwrap {
      // display: flex;
      margin: auto;

      &>.dot {
        cursor: pointer;
        height: 10px;
        width: 10px;
        margin: 0 2px;
        background-color: darken($tertiary, 20%);
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;

        &.activedot,
        .dot:hover {
          background-color: $secondary;
        }
      }
    }
  }
}

.learn-wrapper {
  @extend .prolog-wrapper;
  max-width: 960px;

  @include bp(max, 500px) {
    padding: 15px;
  }

  &>.content-sidebyside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @include bp(max, 768px) {
      flex-direction: column;
    }

    &>.text {
      // font-size: 18px;
      display: flex;

      @include bp(max, 768px) {
        // flex-direction:column;
        order: 1;
      }

      &>.number {
        color: $white;
        font-size: 24px;
        background-color: $secondary;
        border-radius: 50%;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        margin: 15px 25px 15px 0px;

        @include bp(max, 768px) {
          align-self: flex-start;
          width: 30px;
          margin: 15px;
        }
      }

      &>.content {
        &>ul {
          list-style: disc !important;

          @include bp(max, 768px) {
            margin-left: 15px;
          }
        }

        & ul>li {
          margin: 15px auto;
        }

        &>* {
          margin-bottom: 15px;
        }
      }
    }

    &>.image {

      // flex:2;
      img {
        max-height: 375px;
        margin: auto;

        @include bp(max, 500px) {
          max-width: 300px;
        }
      }
    }
  }
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  background: rgba(black, 0.7);
  display: none;
  z-index: 6;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__icon {
    font-size: 4rem !important;
    text-align: center;
    margin-bottom: 0.6rem;
    background-image: linear-gradient(to right, $yellow, $alert);
    -webkit-background-clip: text;
    color: transparent;
  }

  &__ul {
    padding: 1.5em;
    background-color: rgba(darken($white, 3%), 0.9);
    border-radius: 3px;
    max-width: 300px;
  }

  &__list {
    font-size: 1em;
    color: black;

    &--heading {
      font-family: $bold;
      font-size: 1.4em;
      color: $primary;
      text-transform: uppercase;
      text-align: center;
      margin: 0.5em auto;
    }

    &:not(:last-child) {
      margin-bottom: 0.8em;
    }
  }

  &__group {
    text-align: center;
  }
}

.close {
  align-self: flex-end;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  line-height: 20px;
}

.modalExitExam {
  @extend .modalSocial;
}

.modal-videos {
  @extend .modalSocial;
}

.modalRegisAgreement {
  @extend .modalSocial;



}

.modalRegis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__container {
    max-width: 560px;
    margin: 0 auto;

    @include bp(max, $mdWidth) {
      max-width: 300px;
    }


  }

  &__content {
    padding: 1em;
    background-color: #FFFFFF;
  }

  &__button {
    padding: 1em;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    background-color: #CFDBE0;
    padding: .5em;
  }
}

.modalReview {
  @extend .modalSocial;
  display: block;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.modal--box {
  @extend .sectionbox;
}

.blur {
  filter: blur(25px);
}

.closeWatch {
  font-size: 40px;
  cursor: pointer;
  color: $white;
  align-self: flex-end;
  padding-bottom: 15px;

  @include bp(max, 768px) {
    font-size: 20px;
  }
}

.videoWatch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0.5in 3in;

  @include bp(max, 768px) {
    padding: 15px;
  }

  &__container {
    float: none;
    clear: both;
    position: relative;
    padding-bottom: 56.25%;
    /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// spinner modal
.centerspin {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100px;
}

.imageLogo {
  margin-bottom: 10px;

  img {
    max-height: 70px;
  }
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  width: 300px;
  height: 300px;
  background: transparent;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;

  span {
    display: block;
    height: 5px;
    width: 5px;
    background: #49c5fe;
    border-radius: 50%;
    margin: 10px;

    &:nth-child(1) {
      -webkit-animation: bounce 1s ease-in-out infinite;
      animation: bounce 1s ease-in-out infinite;
    }

    &:nth-child(2) {
      -webkit-animation: bounce 1s ease-in-out 0.33s infinite;
      animation: bounce 1s ease-in-out 0.33s infinite;
    }

    &:nth-child(3) {
      -webkit-animation: bounce 1s ease-in-out 0.66s infinite;
      animation: bounce 1s ease-in-out 0.66s infinite;
    }
  }
}

@-webkit-keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  25% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  25% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
