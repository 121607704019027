// content on profile
.content {
  @extend .white-bold;

  .table {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));

    >span:nth-row(odd) {
      background-color: #ccc;
    }
  }

  &>.split {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    align-items: center;

    &>.goals {
      display: flex;
      flex-direction: column;
    }

    &>.input-goals {
      display: flex;
      flex-direction: column;
      height: 192px;

      @include bp(max, 500px) {
        width: 35%;
        height: 192px;
      }

      input {
        height: 24px;

        @include bp(max, 500px) {
          height: 24px;
        }
      }
    }

    &>.steps {
      display: flex;
      flex-direction: column;
    }

    &>.input-steps {
      display: flex;
      flex-direction: column;
    }

    @include bp(max, 500px) {}
  }

  .goals,
  .input-goals,
  .steps,
  .input-steps {
    &>* {
      margin-bottom: 10px;
    }
  }

  .input-style {
    background-color: transparent;
    @extend .yellow-bold;
    text-align: right;
    border: 0;
    font-size: 14px;
  }

  .input-steps {
    @extend .input-style;
  }
}

// card on purchase History
.history {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &>* {
    margin-bottom: 20px;
  }
}

// order history css on profile tab page
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  background: rgba(darken($tertiary, 20%), 0.1);
  border-radius: 4px;
  border: 1px solid $primary; // content

  @include bp(max, $mdWidth) {
    flex-direction: column;
  }

  &>.content {
    display: flex;
    flex-direction: column;
    max-width: 250px;
  }

  &>.content>.title-card {
    @extend .blue-text;
    @include hoverer(color, $secondary, darken($secondary, 15%));
    transition: all ease 0.5s;
  }

  &>.content>.date {
    @extend .body-text-small;
  }

  &>.content>* {
    margin-bottom: 10px;
  }

  // content
  // action
  &>.action {}

  // not yet use please do use this for parents selector
  &>.action>.btn {
    @extend .body-text-small;
    @include hoverer(background, transparent, $secondary);
    @include hoverer(color, $secondary, $white);
    transition: all ease 0.5s;
    color: $secondary;
    border: 1px solid $secondary;
    padding: 0.5em 1em;
  }

  // action
}

// card on purchase History end
// option tab profile
.profile-option {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;

  &>a.tab {
    @include hoverer(color, rgba(white, .4), $white);
    transition: all ease 0.5s;
    cursor: pointer;
    padding: 1em 0.5em;
    font-size: 14px;
    font-weight: 600;
    margin: 5px;
    color: rgba(white, .4);

    @include bp(max, 768px) {
      padding: 0.5em;
      font-size: 13px;
      margin: 2px;
    }
  }

  &>a.active {
    border-bottom: 4px solid $secondary;
    color: $white;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &>* {
    margin-right: 15px;
    margin-bottom: 15px;

    @include bp(max, 768px) {
      // margin-right: auto;
      width: 100%;
      text-align: center;
      margin-right: 0;
    }
  }

  &>*:last-child {
    margin-right: 0;
  }

  &>.red-btn {
    border-radius: 100px;
    @include hoverer(background-color, #ee3d49, darken(#ee3d49, 15%));
    padding: 0.5em 1.5em;
  }

  &>.profile-btn {
    padding: 0.5em 1em;
    @include hoverer(background-color, #transparent, darken($secondary, 10%));
    @include hoverer(color, darken($secondary, 10%), $white);
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid $secondary;
  }

  &>.disabled {
    cursor: not-allowed;
    @include hoverer(color, #8b8b8b, #8b8b8b);
    @include hoverer(background-color, transparent, transparent);
    border: 1px solid #8b8b8b;
  }
}

// span note
.note {
  display: flex;
  max-width: 480px;
  text-align: left;
  margin: auto;
  font-size: 12px;
}

// option tab profile end
.profile-tab {
  display: flex;
  width: 100%;
  justify-content: space-between; // avatar 1st lvl
  align-items: center;

  img {
    width: 100%;
  }

  &>.avatar {
    display: flex;
    flex-direction: column;

    >* {
      margin-bottom: 15px;
    }

    >.avatar__top {
      display: flex;

      >.cont-img>img {
        margin: 0px 20px 0px 0px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0 1px 5px hsla(0, 0%, 0%, 0.25), 0 0 0 1px hsla(0, 0%, 0%, 0.05);
        object-fit: cover; // object-position: -3px -12px;
        object-position: center center;

        @include bp(max, 500px) {
          // margin-top: 20px;
          // margin-right: 15px;
          // width: 60px !important;
          // height: 60px !important;
          display: none;
        }
      }
    }

    &__bottom {}

    @include bp(max, 500px) {}
  }

  // avatar 2nd lvl -info
  &>.avatar>.info {
    &>.title {
      font-size: 24px;
      text-align: left;
      @extend .white-bold;
      border-bottom: 1px solid white;
      margin-bottom: 5px;
    }

    &>.small-info {
      font-size: 14px;
    }
  }



  &>.avatar>.image-journey {
    width: 50px;
    height: 50px;
    margin: 0;
    border-radius: 50%;
    box-shadow: 0 1px 5px hsla(0, 0%, 0%, 0.25), 0 0 0 1px hsla(0, 0%, 0%, 0.05);
    object-position: -3px -3px;

    @include bp(max, 500px) {
      width: 40px;
      height: 40px;
    }
  }

  //
  &>.journey {
    flex-direction: column;
    display: flex;

    @include bp(max, 768px) {
      align-items: center;
    }

    .btn-small {
      margin: 0;
      font-size: 13px;
      text-transform: none;
      border: 1px solid $secondary;
      padding: 0.5em 1em;
      color: $secondary;
      border-radius: 50px;
      transition: all ease 0.5s;
      @include hoverer(color, $secondary, $white);
      @include hoverer(background, transparent, $secondary);

      @include bp(max, 768px) {
        padding: 0.5em;
      }
    }
  }

  #profileimage {
    cursor: pointer;

    &:hover {
      opacity: 1;
      filter: blur(1px);
    }
  }
}

.container__three {
  display: flex;
  margin-top: 15px;

  .wrapIn {
    flex: 1;
    justify-content: center;
  }

  &--divider {
    width: 2px;
    margin: 3px 15px;
    background: white;
  }
}

//IMAGE ROTATE
.rotate0,
.rotate90,
.rotate180,
.rotate270 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
}

#profileimage {
  transform-origin: top left;
  /* IE 10+, Firefox, etc. */
  -webkit-transform-origin: top left;
  /* Chrome */
  -ms-transform-origin: top left;
}

.rotate90 #profileimage {
  transform: rotate(90deg) translateY(-100%);
  -webkit-transform: rotate(90deg) translateY(-100%);
  -ms-transform: rotate(90deg) translateY(-100%);
}

.rotate180 #profileimage {
  transform: rotate(180deg) translate(-100%, -100%);
  -webkit-transform: rotate(180deg) translate(-100%, -100%);
  -ms-transform: rotate(180deg) translateX(-100%, -100%);
}

.rotate270 #profileimage {
  transform: rotate(270deg) translateX(-100%);
  -webkit-transform: rotate(270deg) translateX(-100%);
  -ms-transform: rotate(270deg) translateX(-100%);
}

#profileimage1 {
  transform-origin: top left;
  /* IE 10+, Firefox, etc. */
  -webkit-transform-origin: top left;
  /* Chrome */
  -ms-transform-origin: top left;
}

.rotate90 #profileimage1 {
  transform: rotate(90deg) translateY(-100%);
  -webkit-transform: rotate(90deg) translateY(-100%);
  -ms-transform: rotate(90deg) translateY(-100%);
}

.rotate180 #profileimage1 {
  transform: rotate(180deg) translate(-100%, -100%);
  -webkit-transform: rotate(180deg) translate(-100%, -100%);
  -ms-transform: rotate(180deg) translateX(-100%, -100%);
}

.rotate270 #profileimage1 {
  transform: rotate(270deg) translateX(-100%);
  -webkit-transform: rotate(270deg) translateX(-100%);
  -ms-transform: rotate(270deg) translateX(-100%);
}

#cont-img2 {
  @include bp(max, 500px) {
    // display: none;
  }
}

.bxrotate {
  min-width: 250px;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }

  #cont-img {
    display: flex;
    align-self: center;

    .image {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      box-shadow: 0 1px 5px hsla(0, 0%, 0%, 0.25), 0 0 0 1px hsla(0, 0%, 0%, 0.05);
      object-fit: cover; // object-position: -3px -12px;
      object-position: center right;
    }
  }

  .split {
    display: flex;

    .neobutton {
      height: 50px;
      width: 90px;
      border-radius: 26px;
      min-width: 10px;
      display: flex;
      text-transform: capitalize;
      justify-content: center;
    }
  }
}
